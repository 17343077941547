import { Box, Center } from "@chakra-ui/react"
import { Link } from "gatsby"
import * as React from "react"

const NotFoundPage = () => {
  return (
    <div>
      <Box style={{ marginTop: "30px" }}>
        <Center style={{ fontSize: "30px" }}>404: Page not found</Center>
        <Center>
          <Link to="/">
            <Box style={{ color: "blueviolet", fontSize: "25px" }}>
              Go back to homepage
            </Box>
          </Link>
        </Center>
      </Box>
    </div>
  )
}

export default NotFoundPage
